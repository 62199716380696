<div class="flex justify-center">

    <div class="hidden lg:flex instance-selector-container gap-x-2">

        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'business_center'"></mat-icon>
            </span>


            
            <p-dropdown
            [options]="companies"
            [(ngModel)]="selectedCompany"
            optionLabel="name"
            [filter]="true" 
            filterBy="name"
            dataKey="id"
            (onChange)="onCompanyChange()"
            [placeholder]="'common.company' | transloco"
            [disabled]="companiesDropdownDisabled"
        >

       
            </p-dropdown>
        </div>

        
        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'layers'"></mat-icon>
            </span>

            
            <p-dropdown
            #ddBrands
            [options]="brands"
            [(ngModel)]="selectedBrand"
            optionLabel="name"
            [filter]="true" 
            filterBy="name"
            dataKey="id"
            (onChange)="onBrandChange()"
            [placeholder]="'common.brand' | transloco"
            [disabled]="brandsDropdownDisabled"
            [showClear]="brands.length !== 1"
        >

        </p-dropdown>
        
        </div>

        <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                <mat-icon class="icon-size-5" matPrefix [svgIcon]="'domain'"></mat-icon>
            </span>

            
            <p-dropdown
            [options]="hotels"
            [(ngModel)]="selectedHotel"
            optionLabel="name"
            [filter]="true" 
            filterBy="name"
            dataKey="id"
            (onChange)="onHotelChange()"
            [placeholder]="'common.hotel' | transloco"
            [disabled]="hotelsDropdownDisabled"
            [showClear]="hotels.length !== 1"
        >

        </p-dropdown>
        </div>

        

    </div>
    
    
    <!--Buttons for small screens-->
    <div class="flex lg:hidden">
        <button mat-icon-button aria-label="Companies" class="flex-auto mr-6" [matMenuTriggerFor]="companyMenu">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'business_center'"></mat-icon>
        </button>
        <button
            mat-icon-button
            aria-label="Brands"
            class="flex-auto mr-6"
            [disabled]="brands.length === 0"
            [matMenuTriggerFor]="brandMenu"
        >
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'layers'"></mat-icon>
        </button>
        <button
            mat-icon-button
            aria-label="Hotels"
            class="flex-auto mr-6"
            [disabled]="hotels.length === 0"
            [matMenuTriggerFor]="hotelMenu"
        >
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'domain'"></mat-icon>
        </button>
    </div>

    <mat-menu
        #companyMenu="matMenu"
        [overlapTrigger]="true"
        class="select-menu-toolbar mat-primary"
        xPosition="after"
        yPosition="below"
    >
        <button
            mat-menu-item
            *ngFor="let company of companies"
            (click)="selectCompany(company)"
            class="mat-option"
            [ngClass]="{ 'mat-selected': compareEntities(selectedCompany, company) }"
        >
            {{ company.name }}
        </button>
    </mat-menu>

    <mat-menu
        #brandMenu="matMenu"
        [overlapTrigger]="true"
        class="select-menu-toolbar mat-primary"
        xPosition="after"
        yPosition="below"
    >
        <button
            mat-menu-item
            *ngFor="let brand of brands"
            (click)="selectBrand(brand)"
            class="mat-option"
            [ngClass]="{ 'mat-selected': compareEntities(selectedBrand, brand) }"
        >
            {{ brand.name }}
        </button>
    </mat-menu>

    <mat-menu
        #hotelMenu="matMenu"
        [overlapTrigger]="true"
        class="select-menu-toolbar mat-primary"
        xPosition="after"
        yPosition="below"
    >
        <button
            mat-menu-item
            *ngFor="let hotel of hotels"
            (click)="selectHotel(hotel)"
            class="mat-option"
            [ngClass]="{ 'mat-selected': compareEntities(selectedHotel, hotel) }"
        >
            {{ hotel.name }}
        </button>
    </mat-menu>

</div>
