import { FuseNavigationItem } from "@fuse/components/navigation";
import { CodeModuleConstants } from "../constants/code-module-constants";

const falseFn = (): boolean => false;
const trueFn = (): boolean => true;

export const NavigationData: FuseNavigationItem[] = [
    {
        id: "users",
        title: "menu.user",
        type: "group",
        hidden: falseFn,
        children: [
            {
                id: "users-list",
                title: "menu.user",
                type: "basic",
                hidden: falseFn,
                icon: "person",
                link: "/user/list",
            },
        ],
    },

    {
        id: "analytics",
        title: "menu.analytics",
        type: "group",
        hidden: falseFn,
        children: [
            {
                id: "hotspot_analytics",
                title: "menu.hotspot",
                type: "collapsable",
                icon: "wifi",
                hidden: falseFn,
                children: [
                    /*{
                        id: "statSales",
                        title: "menu.statSales",
                        icon: "assessment",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/hotspot/stat-sales",
                        hidden: trueFn,
                    },*/
                    {
                        id: "traffic",
                        title: "menu.biTraffic",
                        icon: "get_app",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/hotspot/traffic-bi",
                        hidden: trueFn,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    /*{
                        id: "dataAnalytic",
                        title: "menu.dataAnalytic",
                        type: "basic",
                        icon: "data_usage",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/hotspot/data-analytic",
                        hidden: trueFn,
                    },
                    {
                        id: "emailMarketing",
                        title: "menu.emailMarketing",
                        icon: "mail",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/hotspot/email-marketing",
                        hidden: trueFn,
                    },
                    {
                        id: "wifiGlobalData",
                        title: "menu.wifiGlobalData",
                        icon: "wifi",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/hotspot/wifi-global-data",
                        hidden: trueFn,
                    },*/
                ],
            },
            {
                id: "smartroom_analytics",
                title: "menu.smartroom",
                type: "collapsable",
                icon: "offline_bolt",
                classes: {
                    title: CodeModuleConstants.ANALYTICS,
                },
                hidden: trueFn,
                children: [
                    {
                        title: "menu.biEnergySaving",
                        icon: "hotel",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/roomxperience/room-efficiency",
                        id: "energySavingSmartroom",
                        hidden: trueFn,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    /*{
                        title: "menu.biRoomMaintenance",
                        icon: "info",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ANALYTICS,
                        },
                        link: "/analytics/roomxperience/room-maintenance",
                        id: "biRoomMaintenance",
                        hidden: trueFn,
                    },*/
                ],
            },
        ],
    },

    {
        id: "management",
        title: "menu.management",
        type: "group",
        hidden: trueFn,
        children: [
            {
                id: "company",
                title: "menu.company",
                type: "basic",
                icon: "business_center",
                hidden: trueFn,
                exactMatch: false,
                link: "/company",
            },
            {
                id: "brand",
                title: "menu.brand",
                type: "basic",
                icon: "layers",
                hidden: trueFn,
                exactMatch: false,
                link: "/brand",
            },
            {
                id: "hotel",
                title: "menu.hotel",
                type: "basic",
                icon: "domain",
                hidden: trueFn,
                exactMatch: false,
                link: "/hotel",
            },
            {
                id: "arq-hotel",
                title: "menu.arq-hotel",
                type: "collapsable",
                icon: "domain",
                hidden: trueFn,
                children: [
                    {
                        id: "building",
                        title: "menu.building",
                        type: "basic",
                        icon: "domain",
                        hidden: trueFn,
                        exactMatch: false,
                        link: "/hotel-structure/building",
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "floor",
                        title: "menu.floor",
                        type: "basic",
                        icon: "domain",
                        hidden: trueFn,
                        exactMatch: false,
                        link: "/hotel-structure/floor",
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "room",
                        title: "menu.room",
                        type: "basic",
                        icon: "hotel",
                        hidden: trueFn,
                        exactMatch: false,
                        link: "/hotel-structure/room",
                        meta: {
                            hotelRequired: true,
                        },
                    },
                ],
            },
        ],
    },
    {
        id: "module",
        title: "menu.module",
        type: "group",
        hidden: trueFn,
        exactMatch: true,
        link: "/module/home",
        children: [
            {
                id: "modulesConfig",
                title: "menu.modulesConfig",
                type: "basic",
                icon: "view_module",
                hidden: trueFn,
                exactMatch: false,
                link: "/module/home",
                meta: {
                    brandRequired: true,
                },
            },
            {
                id: "hotspot",
                title: "menu.hotspot",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                icon: "wifi",
                hidden: trueFn,
                children: [
                    {
                        id: "settingHotspot",
                        title: "menu.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "settings",
                        link: "/module/hotspot/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "hotspotWhiteList",
                        title: "menu.hotspotWhiteList",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "verified_user",
                        link: "/module/hotspot/whitelist",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "hotspotBlackList",
                        title: "menu.hotspotBlackList",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "block",
                        link: "/module/hotspot/blacklist",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "server",
                        title: "menu.server",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "router",
                        link: "/module/hotspot/server",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "profile",
                        title: "menu.profile",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "assignment_ind",
                        link: "/module/hotspot/profile",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "card",
                        title: "menu.card",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "payment",
                        link: "/module/hotspot/card",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "devices",
                        title: "menu.devices",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "devices",
                        link: "/module/hotspot/devices",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "cardTransaction",
                        title: "menu.cardTransaction",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "repeat",
                        link: "/module/hotspot/card-transaction",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "cardTemplate",
                        title: "menu.cardTemplate",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "web",
                        link: "/module/hotspot/card-template",
                        hidden: trueFn,
                        exactMatch: true,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "hotspotEmailValidation",
                        title: "menu.emailValidation",
                        icon: "mail",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        link: "/module/hotspot/email-validation",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "hotspotNasRadius",
                        title: "menu.nasRadius",
                        icon: "vpn_key",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        link: "/module/hotspot/nas-radius",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "hotspotStatus",
                        title: "menu.hotspotStatus",
                        icon: "bar_chart",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        link: "/module/hotspot/status",
                        hidden: trueFn,
                        exactMatch: true,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "hotspotRouter",
                        title: "menu.hotspotRouter",
                        icon: "router",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        link: "/module/hotspot/router",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "hotspotCmsMenu",
                        title: "menu.hotspotCms",
                        type: "collapsable",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "view_quilt",
                        hidden: trueFn,
                        children: [
                            {
                                id: "hotspotCmsSsid",
                                title: "menu.hotspotCmsSsid",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.HOTSPOT,
                                },
                                icon: "wifi",
                                link: "/module/hotspot/cms/ssid",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "hotspotCmsTemplate",
                                title: "menu.hotspotCmsTemplate",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.HOTSPOT,
                                },
                                icon: "description",
                                link: "/module/hotspot/cms/template",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "hotspotCmsSetting",
                                title: "menu.hotspotCmsSetting",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.HOTSPOT,
                                },
                                icon: "list",
                                link: "/module/hotspot/cms/setting",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "hotspotCmsLiterals",
                                title: "menu.hotspotCmsLiterals",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.HOTSPOT,
                                },
                                icon: "language",
                                link: "/module/hotspot/cms/literals",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                        ],
                    },
                ],
            },
            {
                id: "iptv",
                title: "menu.iptv",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.INTERACTIVE_TV,
                },
                icon: "tv",
                hidden: trueFn,
                children: [
                    {
                        id: "settingIPTV",
                        title: "menu.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.INTERACTIVE_TV,
                        },
                        icon: "settings",
                        link: "/module/interactive-tv/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "iptv_devicedata",
                        title: "menu.devicedata",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.INTERACTIVE_TV,
                        },
                        icon: "devices",
                        link: "/module/interactive-tv/device-data",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                ],
            },
            {
                id: "cast",
                title: "menu.cast",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.CAST,
                },
                icon: "cast",
                hidden: trueFn,
                children: [
                    {
                        id: "settingCast",
                        title: "menu.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.CAST,
                        },
                        icon: "settings",
                        link: "/module/cast/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "castchromecastconfig",
                        title: "menu.chromecastConfig",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.CAST,
                        },
                        icon: "build",
                        link: "/module/cast/chromecast-config",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "castchromecast",
                        title: "menu.chromecast",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.CAST,
                        },
                        icon: "cast",
                        link: "/module/cast/chromecast",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "castdatausage",
                        title: "menu.dataUsage",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.CAST,
                        },
                        icon: "devices",
                        link: "/module/cast/data-usage",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "castguestdevice",
                        title: "menu.guestDevice",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.CAST,
                        },
                        icon: "computer",
                        link: "/module/cast/guest-device",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "serverConfig",
                        title: "menu.serverConfig",
                        icon: "branding_watermark",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.CAST,
                        },
                        link: "/module/cast/server-config",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                ],
            },
            {
                id: "smartroom",
                title: "menu.smartroom",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.ROOM_XPERIENCE,
                },
                icon: "offline_bolt",
                hidden: trueFn,
                children: [
                    {
                        id: "globalSettingSmartroom",
                        title: "menu.globalSetting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "settings",
                        link: "/module/room-xperience/global-setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "settingSmartroom",
                        title: "menu.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "settings",
                        link: "/module/room-xperience/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "smartroomMqttRt",
                        title: "menu.smartroom-mqtt-rt",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "local_hotel",
                        link: "/module/room-xperience/room-status-mqtt",
                        hidden: trueFn,
                        exactMatch: true,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "smartroomRs",
                        title: "menu.smartroom-rs",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "room_service",
                        link: "/module/room-xperience/room-service",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },

                    {
                        id: "smartroomCI",
                        title: "menu.checkInstallation",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "downloading",
                        link: "/module/room-xperience/check-installation",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "smartroomInfo",
                        title: "menu.smartroom-info",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "info",
                        link: "/module/room-xperience/room-maintenance",
                        hidden: trueFn,
                        exactMatch: true,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "productionLive",
                        title: "menu.production",
                        icon: "power",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        link: "/module/room-xperience/production-live",
                        hidden: trueFn,
                        exactMatch: true,
                    },
                    {
                        id: "thermostat",
                        title: "menu.thermostat",
                        icon: "ac_unit",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        link: "/module/room-xperience/thermostat",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "logo",
                        title: "menu.logo",
                        icon: "branding_watermark",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        link: "/module/room-xperience/logo",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "serverConfig",
                        title: "menu.serverConfig",
                        icon: "branding_watermark",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        link: "/module/room-xperience/server-config",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "smartroomController",
                        title: "menu.smartroomController",
                        type: "collapsable",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        icon: "view_quilt",
                        hidden: trueFn,
                        meta: {
                            hotelRequired: true,
                        },
                        children: [
                            {
                                id: "controllerSignal",
                                title: "menu.controllerSignal",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.ROOM_XPERIENCE,
                                },
                                icon: "sensors",
                                link: "/module/room-xperience/controller/signal-map",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "controllerSignalMask",
                                title: "menu.controllerSignalMask",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.ROOM_XPERIENCE,
                                },
                                icon: "sensors",
                                link: "/module/room-xperience/controller/signal-mask",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                        ],
                    },

                    {
                        id: "translationSmartroom",
                        title: "menu.translation",
                        icon: "translate",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.ROOM_XPERIENCE,
                        },
                        link: "/module/room-xperience/translation",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                ],
            },
            {
                id: "srvmgm",
                title: "menu.srvmgm",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.SRVMGM,
                },
                icon: "contacts",
                hidden: trueFn,
                children: [
                    {
                        id: "settingSrvmgm",
                        title: "menu.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "settings",
                        link: "/module/srvmgm/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "serviceSrvmgm",
                        title: "menu.service",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "clear_all",
                        link: "/module/srvmgm/service",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "banner",
                        title: "menu.banner",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "list",
                        link: "/module/srvmgm/banner",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "listmediaIPTV",
                        title: "menu.listmedia",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "list",
                        link: "/module/srvmgm/media",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "listchannelIPTV",
                        title: "menu.listchannel",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "list",
                        link: "/module/srvmgm/channel",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "listappIPTV",
                        title: "menu.listapp",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "list",
                        link: "/module/srvmgm/app",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "listurlIPTV",
                        title: "menu.listurl",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "list",
                        link: "/module/srvmgm/url",
                        hidden: trueFn,
                        exactMatch: false,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "layout",
                        title: "menu.layout",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "web",
                        link: "/module/srvmgm/layout",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "baselayout",
                        title: "menu.baselayout",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "web",
                        link: "/module/srvmgm/base-layout",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "pages",
                        title: "menu.pages",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "description",
                        link: "/module/srvmgm/page",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "translation",
                        title: "menu.translation",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "translate",
                        link: "/module/srvmgm/translation",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "foodService",
                        title: "menu.foodService.foodService",
                        type: "collapsable",
                        classes: {
                            title: CodeModuleConstants.SRVMGM,
                        },
                        icon: "restaurant",
                        exactMatch: true,
                        hidden: trueFn,
                        children: [
                            {
                                id: "restaurant",
                                title: "menu.foodService.restaurant",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.SRVMGM,
                                },
                                icon: "restaurant_menu",
                                link: "/module/srvmgm/food/restaurant",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "menu",
                                title: "menu.foodService.menu",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.SRVMGM,
                                },
                                icon: "import_contacts",
                                link: "/module/srvmgm/food/menu",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "allergy",
                                title: "menu.foodService.allergy",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.SRVMGM,
                                },
                                icon: "healing",
                                link: "/module/srvmgm/food/allergy",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "category",
                                title: "menu.foodService.category",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.SRVMGM,
                                },
                                icon: "category",
                                link: "/module/srvmgm/food/category",
                                hidden: trueFn,
                                exactMatch: false,
                            },
                            {
                                id: "lang",
                                title: "menu.foodService.lang",
                                type: "basic",
                                classes: {
                                    title: CodeModuleConstants.SRVMGM,
                                },
                                exactMatch: true,
                                icon: "language",
                                link: "/module/srvmgm/food/lang",
                                hidden: trueFn,
                            },
                        ],
                    },
                ],
            },
            {
                id: "booking",
                title: "menu.booking",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.BOOKING,
                },
                icon: "event_note",
                hidden: trueFn,
                children: [
                    {
                        id: "bookingGlobalSetting",
                        title: "module.booking.hotelSetting.configGlobal",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.BOOKING,
                        },
                        icon: "settings",
                        link: "/module/booking/global-setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "bookingHotelSetting",
                        title: "module.booking.hotelSetting.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.BOOKING,
                        },
                        icon: "settings",
                        link: "/module/booking/hotel-setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "bookingHotelInfo",
                        title: "menu.bookingInfo",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.BOOKING,
                        },
                        icon: "recent_actors",
                        link: "/module/booking/hotel-info",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                ],
            },
            {
                id: "netflow",
                title: "menu.netflow",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.NETFLOW,
                },
                icon: "swap_vert",
                hidden: trueFn,
                children: [
                    {
                        id: "settingNetflow",
                        title: "menu.setting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.NETFLOW,
                        },
                        icon: "settings",
                        link: "/module/netflow/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                ],
            },
        ],
    },
    {
        id: "global",
        title: "menu.global",
        type: "group",
        hidden: trueFn,
        children: [
            {
                id: "emailTemplate",
                title: "menu.emailTemplate",
                type: "basic",
                icon: "mail",
                link: "/email-template",
                hidden: trueFn,
                exactMatch: false,
            },
            {
                id: "currency",
                title: "menu.currency",
                type: "basic",
                icon: "monetization_on",
                link: "/currency",
                hidden: trueFn,
                exactMatch: false,
            },
            {
                id: "pricePlan",
                title: "menu.pricingPlan",
                type: "basic",
                icon: "monetization_on",
                link: "/api-pricing-plan",
                hidden: trueFn,
                exactMatch: false,
            },
            {
                id: "faq",
                title: "menu.faq",
                type: "basic",
                icon: "question_answer",
                link: "/faq",
                hidden: trueFn,
                exactMatch: false,
            },
            {
                id: "manual",
                title: "menu.adminManual",
                type: "basic",
                icon: "description",
                link: "/manual",
                hidden: trueFn,
                exactMatch: false,
            },
            {
                id: "pms",
                title: "menu.pms",
                type: "collapsable",
                icon: "cloud",
                hidden: trueFn,
                children: [
                    {
                        id: "pmsGroup",
                        title: "menu.group",
                        type: "basic",
                        icon: "group_work",
                        link: "/pms/group",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "pmsFunctionality",
                        title: "menu.functionality",
                        type: "basic",
                        icon: "view_module",
                        link: "/pms/functionality",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "pmsBean",
                        title: "menu.bean",
                        type: "basic",
                        icon: "scatter_plot",
                        link: "/pms/bean",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "pmsRelationship",
                        title: "menu.relationship",
                        type: "basic",
                        icon: "compare_arrows",
                        link: "/pms/relationship",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                ],
            },
            {
                id: "updater",
                title: "menu.updater",
                type: "collapsable",
                icon: "system_update_alt",
                hidden: trueFn,
                children: [
                    {
                        id: "updaterModule",
                        title: "menu.updaterModule",
                        type: "basic",
                        icon: "view_module",
                        link: "/updater/module-version",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "updaterGit",
                        title: "menu.updaterGit",
                        type: "basic",
                        icon: "merge_type",
                        link: "/updater/git",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "updaterConfigurationHotel",
                        title: "menu.updaterConfigurationHotel",
                        type: "basic",
                        icon: "settings_suggest",
                        link: "/updater/configuration-hotel",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "updaterExecutions",
                        title: "menu.updaterExecutions",
                        type: "basic",
                        icon: "access_time",
                        link: "/updater/executions",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "updaterServer",
                        title: "menu.updaterServer",
                        type: "basic",
                        icon: "storage",
                        link: "/updater/server",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "updaterChecker",
                        title: "menu.updaterChecker",
                        type: "basic",
                        icon: "check_circle_outline",
                        link: "/updater/checker",
                        hidden: trueFn,
                        exactMatch: true,
                    },
                    {
                        id: "updaterFiles",
                        title: "menu.updaterFiles",
                        type: "basic",
                        icon: "file_copy",
                        link: "/updater/files",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    
                    {
                        id: "updaterCertificate",
                        title: "menu.updaterCertificate", 
                        type: "basic",
                        icon: "access_time",
                        link: "/updater/certificate",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                     
                    {
                        id: "updaterConfiguration",
                        title: "menu.updaterConfiguration", 
                        type: "basic",
                        icon: "access_time",
                        link: "/updater/configuration",
                        hidden: trueFn,
                        exactMatch: false,
                    },

                    {
                        id: "updaterNetwork",
                        title: "menu.updaterNetwork", 
                        type: "basic",
                        icon: "access_time",
                        link: "/updater/network",
                        hidden: trueFn,
                        exactMatch: false,
                    },

                    
                ],
            },
            {
                id: "utils",
                title: "menu.utils",
                type: "collapsable",
                icon: "build",
                hidden: trueFn,
                children: [
                    {
                        id: "mail",
                        title: "menu.mail",
                        type: "basic",
                        icon: "mail_outline",
                        link: "/utils/mail",
                        hidden: falseFn,
                    },
                    {
                        id: "hardwareMonitor",
                        title: "menu.hardwareMonitor",
                        type: "collapsable",
                        icon: "desktop_windows",
                        hidden: trueFn,
                        children: [
                            {
                                id: "hardwareMonitor",
                                title: "menu.hardwareMonitorChecker",
                                type: "basic",
                                icon: "desktop_windows",
                                link: "/utils/hw-monitor/checker",
                                hidden: falseFn,
                            },
                            {
                                id: "hardwareMonitorParameters",
                                title: "menu.hardwareMonitorParameters",
                                type: "basic",
                                icon: "tune",
                                link: "/utils/hw-monitor/parameters",
                                hidden: falseFn,
                            },
                        ],
                    },
                    {
                        id: "permissions",
                        title: "menu.permissions",
                        type: "basic",
                        icon: "security",
                        link: "/utils/permissions",
                        hidden: falseFn,
                        exactMatch: false,
                    },
                    {
                        id: "changelog",
                        title: "menu.changelog",
                        type: "basic",
                        icon: "update",
                        link: "/utils/changelog",
                        hidden: falseFn,
                        exactMatch: false,
                    },
                    {
                        id: "moduleNodeInfo",
                        title: "menu.moduleNodeInfo",
                        type: "basic",
                        icon: "notes",
                        link: "/utils/module-info",
                        hidden: falseFn,
                        exactMatch: false,
                    },
                    {
                        id: "country",
                        title: "menu.country",
                        type: "basic",
                        icon: "public",
                        link: "/utils/country",
                        hidden: falseFn,
                        exactMatch: false,
                    },
                    {
                        id: "alert",
                        title: "menu.alert",
                        type: "basic",
                        icon: "warning",
                        link: "/utils/alert",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "logging",
                        title: "menu.logging",
                        type: "basic",
                        icon: "file_copy",
                        link: "/utils/logging",
                        hidden: falseFn,
                        exactMatch: false,
                    },
                    {
                        id: "macInfo",
                        title: "menu.macChecker",
                        type: "basic",
                        icon: "info",
                        link: "/utils/mac-info",
                        hidden: falseFn,
                        meta: {
                            hotelRequired: true,
                        },
                    },
                    {
                        id: "systemAudit",
                        title: "menu.systemAudit",
                        type: "basic",
                        icon: "info",
                        link: "/utils/system-audit",
                        hidden: falseFn,
                    },
                ],
            },
        ],
    },
];

export const NavigationDataNOC: FuseNavigationItem[] = [
    {
        id: "noc",
        title: "menu.noc",
        type: "group",
        hidden: falseFn,
        children: [
            {
                id: "hotspotWhiteList",
                title: "menu.hotspotWhiteList",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                icon: "verified_user",
                link: "/module/hotspot/whitelist",
                hidden: falseFn,
            },
            {
                id: "hotspotBlackList",
                title: "menu.hotspotBlackList",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                icon: "block",
                link: "/module/hotspot/blacklist",
                hidden: falseFn,
            },
            {
                id: "profile",
                title: "menu.profile",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                icon: "assignment_ind",
                link: "/module/hotspot/profile",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "cardRadius",
                title: "menu.card",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                icon: "payment",
                link: "/module/hotspot/card",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            // {
            //     id: "devices",
            //     title: "menu.devices",
            //     type: "basic",
            //     classes: {
            //         title: CodeModuleConstants.HOTSPOT,
            //     },
            //     icon: "devices",
            //     link: "/module/hotspot/devices",
            //     hidden: falseFn,
            //     meta: {
            //         hotelRequired: true,
            //     },
            // },
            {
                id: "hotspotEmailValidation",
                title: "menu.emailValidation",
                icon: "mail",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                link: "/module/hotspot/email-validation",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "hotspotNasRadius",
                title: "menu.nasRadius",
                icon: "vpn_key",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                link: "/module/hotspot/nas-radius",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "hotspotStatus",
                title: "menu.hotspotStatus",
                icon: "bar_chart",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                link: "/module/hotspot/status",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "biTraffic",
                title: "menu.biTraffic",
                icon: "get_app",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.ANALYTICS,
                },
                link: "/analytics/hotspot/traffic-bi",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "macInfo",
                title: "menu.macChecker",
                type: "basic",
                icon: "info",
                link: "/utils/mac-info",
                hidden: falseFn,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "hotspotCmsMenu",
                title: "menu.hotspotCms",
                type: "collapsable",
                classes: {
                    title: CodeModuleConstants.HOTSPOT,
                },
                icon: "view_quilt",
                hidden: trueFn,
                children: [
                    {
                        id: "hotspotCmsSsid",
                        title: "menu.hotspotCmsSsid",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "wifi",
                        link: "/module/hotspot/cms/ssid",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "hotspotCmsTemplate",
                        title: "menu.hotspotCmsTemplate",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "description",
                        link: "/module/hotspot/cms/template",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "hotspotCmsSetting",
                        title: "menu.hotspotCmsSetting",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "list",
                        link: "/module/hotspot/cms/setting",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                    {
                        id: "hotspotCmsLiterals",
                        title: "menu.hotspotCmsLiterals",
                        type: "basic",
                        classes: {
                            title: CodeModuleConstants.HOTSPOT,
                        },
                        icon: "language",
                        link: "/module/hotspot/cms/literals",
                        hidden: trueFn,
                        exactMatch: false,
                    },
                ],
            },
            {
                id: "smartroomRt",
                title: "menu.smartroom-rt",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.ROOM_XPERIENCE,
                },
                icon: "local_hotel",
                link: "/module/room-xperience/room-status",
                hidden: trueFn,
                exactMatch: true,
                meta: {
                    hotelRequired: true,
                },
            },
            {
                id: "smartroomInfo",
                title: "menu.smartroom-info",
                type: "basic",
                classes: {
                    title: CodeModuleConstants.ROOM_XPERIENCE,
                },
                icon: "info",
                link: "/module/room-xperience/room-maintenance",
                hidden: trueFn,
                exactMatch: true,
                meta: {
                    hotelRequired: true,
                },
            },
        ],
    },
];
